import React, { useEffect } from 'react';
import styles from './index.module.less';
import { getAuthUrl } from '../../effetcs/loginService';

export const DingDTFLogin = () => {
  useEffect(() => {
    (async () => {
      const urlSearchParams = new URLSearchParams(location.search);
      const service = urlSearchParams.get('service');

      const { redirectUri = '', clientId = '' } = await getAuthUrl();

      (window as any).DTFrameLogin(
        {
          id: 'ding-dtflogin',
          width: 300,
          height: 300,
        },
        {
          redirect_uri: encodeURIComponent(
            `${redirectUri}${service ? `?service=${encodeURIComponent(service)}` : ''}`,
          ),
          client_id: clientId,
          scope: 'openid',
          response_type: 'code',
          prompt: 'consent',
          // state: 'test',
        },
        (loginResult: any) => {
          const { redirectUrl, authCode } = loginResult;
          console.log('loginResult', loginResult);

          // 这里可以直接进行重定向
          window.location.href = redirectUrl;
          // 也可以在不跳转页面的情况下，使用code进行授权
          console.log(authCode);
        },
        (errorMsg: any) => {
          // 这里一般需要展示登录失败的具体原因
          alert(`Login Error: ${errorMsg}`);
        },
      );
    })();
  }, []);

  return (
    <div className={styles['ding-Login']}>
      <span>手机扫码安全登录</span>
      <div id="ding-dtflogin" className={styles['ding-dtflogin']}></div>
      <span>打开手机钉钉App扫码</span>
    </div>
  );
};

export default DingDTFLogin;
