import React, { CSSProperties, useEffect } from 'react';
import styles from './inde.module.less';
import { Button, Form, Input, Popover, Select } from 'antd';
import { CheckCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useRegisterStore } from './useRegisterStore';
import { isDingtalk } from '@/utils/isDingtalk';
import { isMobile } from '@/utils/isMobile';
import { useSystemStore } from '@/hooks/useSystemConfig';
import { usePreferenceStore } from '@/hooks/usePreferenceConfig';
import IconFont from '@bui/iconfont';
import { useTranslate } from '@/hooks/useTranslate';
import loginBackGroundImage from '@/images/login-background.jpg';
import { getLang, setLang } from '@bui/eimos-sdk/dist/sdk/lang';

const Register: React.FC = () => {
  const sysStore = useSystemStore((state) => {
    return {
      init: state.init,
      inited: state.inited,
    };
  });

  const preferenceStore = usePreferenceStore((state) => {
    return {
      langs: state.langs,
      getLangs: state.getLangs,
    };
  });

  useEffect(() => {
    sysStore.init({ requestMode: 'old' });
    preferenceStore.getLangs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // const [logoUrl] = useSystemByKey('logoUrl');
  // const logo = logoUrl || require('@/images/login-logo.png');
  const eimos = require('@/images/EIMOS.png');
  const eimosTitle = require('@/images/logo@2x.webp');
  const [form] = Form.useForm();
  const isDingTalk = isDingtalk();
  const isMobileDevice = isMobile();
  const isDingTalkMobile = isDingTalk && isMobileDevice;

  const t = useTranslate();

  const store = useRegisterStore();

  useEffect(() => {
    store.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log('store', store);

  // useEffect(() => {
  //   if (isDingTalkMobile) {
  //     const dingLoginUrl = `${location.protocol}//${location.host}/eimos-h5/`;
  //     console.log('检测到钉钉环境，将跳转到钉钉登录', dingLoginUrl);
  //     const urlSearchParams = new URLSearchParams(location.search);
  //     const redirectUrl = urlSearchParams.get('service');
  //     if (redirectUrl) {
  //       location.href = `${dingLoginUrl}?service=${redirectUrl}`;
  //       return;
  //     }
  //     location.href = dingLoginUrl;
  //     return;
  //   }
  //   init();
  // }, []);

  if (!sysStore.inited) {
    return null;
  }

  // const handleError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
  //   e.currentTarget.onerror = null;
  //   console.log('logo图片加载错误,图片地址为:', logo);
  //   e.currentTarget.src = require('@/images/login-logo.png');
  // };

  const lang = getLang() || 'zh-CN';

  const langLabelMap: Record<string, string> = {
    'zh-CN': '中文',
    'en-US': 'English',
  };

  const renderLangMenu = () => {
    return (
      <div>
        {preferenceStore.langs.map((item) => {
          return (
            <p
              onClick={() => {
                setLang(item.value);
                localStorage.setItem('eimos:lang', item.value);
                location.reload();
                // updateLang({
                //   code: item.value,
                // } as PreferenceConfigType['lang']).then(() => {
                //   location.reload();
                // });
              }}
              className={styles['overlap-a']}
              key={item.value}
            >
              {item.label}
              {lang === item.value ? (
                <IconFont className={styles['checked']} type="icon-check" />
              ) : null}
            </p>
          );
        })}
      </div>
    );
  };

  const renderLangEntry = () => {
    if (!preferenceStore.langs?.length) {
      return null;
    }
    return (
      <Popover
        content={renderLangMenu()}
        title={t('SystemLanguage', '系统语言')}
        overlayClassName={styles['user-info']}
      >
        <div className={styles['right-menu-item']}>
          <IconFont type="icon-diqiu" style={{ fontSize: '20px' }} />
          <span>{langLabelMap[lang]}</span>
        </div>
      </Popover>
    );
  };

  const backGroundStyle: CSSProperties = {
    backgroundImage: `url(${loginBackGroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
    position: 'relative',
  };

  const centerImg = require('@/images/login-background2.png');

  // 登录页标题
  const CompanyInfo = (
    <div className={styles['company-info']}>
      <div
        style={{
          fontFamily: 'PingFangSC, PingFang SC',
          fontWeight: 500,
          fontSize: '24px',
          color: '#131E29',
          marginBottom: 13,
        }}
      >
        欢迎注册
      </div>
      <img src={eimos} style={{ width: 127, height: 30, marginBottom: 16 }} alt="EIMOS"></img>
      <div className={styles['subtitle']}>您的企业经营管理业务专家</div>
    </div>
  );
  const isUpdate = false;

  const onRegister = async () => {
    const values = await form.validateFields();
    console.log(values);
    await store.register(values);
    const container = document.querySelector(`.${styles['login-block']}`);
    container?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const renderForm = () => {
    if (store.success) {
      return (
        <div className={styles['register-success']}>
          <CheckCircleOutlined className={styles['register-success-icon']} />
          <div className={styles['register-success-tip']}>注册成功，请注意查收邮件！</div>
        </div>
      );
    }
    return (
      <Form className={styles['register-form']} form={form} layout="vertical">
        <Form.Item
          label="企业名称"
          name="enterpriseName"
          rules={[
            {
              required: true,
              message: t('register.enterpriseName.valid.empty', '企业名称不能为空'),
            },
            {
              // pattern: /^[a-z0-9]{1,10}$/,
              // message: t('NotEmptyTooltip', '格式只能为小写字母和数字的组合'),
              max: 100,
              message: t('MaxLength100', '最大长度为100个字符'),
            },
          ]}
        >
          <Input
            placeholder={t('enterpriseName', '企业名称')}
            type="text"
            auto-complete="off"
            prefix={<IconFont type="icon-qiye2" style={{ color: '#9A9FA3', fontSize: 24 }} />}
          />
        </Form.Item>
        <Form.Item
          label={
            <Popover
              content={t('tenantCodeDescription', 'EIMOS 系统中的企业唯一标识，注册成功后不可修改')}
              title={t('tenantCode', '企业代码')}
              trigger="click"
            >
              企业代码
              <QuestionCircleOutlined style={{ marginLeft: 3 }} />
            </Popover>
          }
          name="tenantCode"
          rules={[
            { required: true, message: t('tenantCode.not.empty', '企业代码不能为空') },
            {
              max: 10,
              message: t('MaxLength10', '最大长度为10个字符'),
            },
            {
              pattern: /^[a-z0-9]{1,10}$/,
              message: t('tenantCode.valid.format', '格式只能为小写字母和数字的组合'),
            },
          ]}
        >
          <Input
            placeholder={t('tenantCode', '企业代码')}
            type="text"
            auto-complete="off"
            prefix={<IconFont type="icon-qi" style={{ color: '#9A9FA3', fontSize: 24 }} />}
          />
        </Form.Item>
        <Form.Item
          label={t('phone', '联系电话')}
          name="enterpriseContactPhone"
          rules={[
            { required: true, message: t('phone.not.empty', '电话不能为空') },
            {
              pattern: /^1(3\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\d|9[0-35-9])\d{8}$/,
              message: t('phone.valid.format', '电话格式不正确'),
            },
          ]}
        >
          <Input
            placeholder={t('phone', '联系电话')}
            type="number"
            auto-complete="off"
            prefix={<IconFont type="icon-phone" style={{ color: '#9A9FA3', fontSize: 24 }} />}
          />
        </Form.Item>
        <Form.Item
          label={t('email', '邮箱')}
          name="enterpriseContactEmail"
          rules={[
            { required: true, message: t('email.not.empty', '邮箱不能为空') },
            {
              pattern: /^\w+(-+.\w+)*@\w+(-.\w+)*.\w+(-.\w+)*$/,
              message: t('mail.valid.format', '邮箱格式不正确'),
            },
          ]}
        >
          <Input
            placeholder={t('Email', t('email', '邮箱'))}
            type="email"
            auto-complete="off"
            prefix={<IconFont type="icon-email" style={{ color: '#9A9FA3', fontSize: 24 }} />}
          />
        </Form.Item>
        <Form.Item label={t('enterpriseIndustry', '行业')} name="enterpriseIndustry">
          <Select
            size="large"
            showSearch
            placeholder={t('enterpriseIndustry', '行业')}
            // menuItemSelectedIcon={<IconFont type="icon-industry" />}
            optionFilterProp="children"
            onSearch={() => {
              // onSearch
            }}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={store.industries}
          />
        </Form.Item>

        <Form.Item label={t('enterpriseScale', '企业规模')} name="enterpriseScale">
          <Select
            showSearch
            size="large"
            placeholder={t('enterpriseScale', '企业规模')}
            optionFilterProp="children"
            onSearch={() => {
              // onSearch
            }}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={store.scales}
          />
        </Form.Item>

        <Button
          size="middle"
          loading={!!store.loading.handleLogin}
          className={styles['login-button']}
          onClick={(e) => {
            e.preventDefault();
            onRegister();
          }}
        >
          {!store.loading.register ? (
            <span>{t('Register', '注册')}</span>
          ) : (
            <span>{t('Registering', '注册中')}...</span>
          )}
        </Button>
      </Form>
    );
  };

  const renderContent = () => {
    return (
      <div className={styles['login-block']}>
        {CompanyInfo}
        <div className={styles['form-wrapper']}>{renderForm()}</div>
      </div>
    );
  };

  return (
    <div style={{ overflow: 'hidden', height: '100%', minHeight: '600px', ...backGroundStyle }}>
      <img src={centerImg} className={styles['center-img']} />
      <img src={eimosTitle} className={styles['eimos-title-img']} />
      {!isDingTalkMobile && (
        <div className={styles['login']}>
          {isUpdate ? null : renderContent()}
          {/* 底部 */}
          <div className={styles['login-footer']}>
            <div>Copyright © 2020-2022 boulderai All Rights Reserved.</div>
          </div>
        </div>
      )}

      {/* 语言设置 */}
      <div className={styles['system-lang']}>{renderLangEntry()}</div>
    </div>
  );
};

export default Register;
