import ReactDOM from 'react-dom';
import Login from './pages/Login';
import { DingDTFLogin } from './pages/Login/components';
import React from 'react';
import './global.less';
import { useRoutes } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
import { LngProvider } from '@bui/i18n';
import Register from './pages/Register';
import '@bui/eimos-sdk/dist/sdk';

export const LoginPage = () => {
  const element = useRoutes([
    {
      path: '/login',
      element: <Login />,
    },
    {
      path: '/login/dingDtfLogin',
      element: <DingDTFLogin />,
    },
    {
      path: '/login/register',
      element: <Register />,
    },
  ]);
  return element;
};

ReactDOM.render(
  <BrowserRouter>
    <LngProvider classes={['eimos']}>
      <LoginPage />
    </LngProvider>
  </BrowserRouter>,
  document.querySelector('#EIMOS-APP'),
);
