import React, { FC } from 'react';
import styles from '../inde.module.less';

type CompanyInfoProps = {
  isAiLoginStyle?: boolean;
  url?: string;
};

// 登录页标题
export const CompanyInfo: FC<CompanyInfoProps> = (props) => {
  const { isAiLoginStyle, url } = props;
  if (isAiLoginStyle) {
    return (
      <div className={styles['company-info']}>
        <div className={styles['title-text']}>登录到AI问数助手</div>
        <div className={styles['subtitle']}>您的政务数据助手</div>
      </div>
    );
  }

  const eimos = require('@/images/welcome.png');
  return (
    <div className={styles['company-info']}>
      <img src={url ?? eimos} style={{ width: 300, height: 110, marginBottom: 52 }}></img>
    </div>
  );
};
