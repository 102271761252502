import { useState } from 'react';
import { decrypt, encrypt } from '@/utils/jsencrypt';
import { useLoginStore } from '../useLoginStore';
import { getKeycloak, updatePwd } from './loginService';
import { message } from 'antd';
import { getConfig } from '@/pages/UserCenter/ThirdPartyConfig/effects/service';
import { CONFIG_ITEM } from '@/pages/UserCenter/ThirdPartyConfig/effects/const';
import { ConfigItem } from '@/pages/UserCenter/ThirdPartyConfig/effects';

const useLogin = ({ form }: any) => {
  const [codeUrl, setCodeUrl] = useState<string>('');
  const [uuid, setUuid] = useState<string>('');
  const [isValidate, setIsValidate] = useState<boolean>(true);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [thirdConfig, setThirdConfig] = useState<ConfigItem[]>([]);

  const store = useLoginStore();

  // 登录
  const handleLogin = () => {
    form.validateFields().then(async () => {
      const formData = form.getFieldsValue();
      const { username, password, code = '', remember = false } = formData || {};
      if (remember) {
        localStorage.setItem('username', username);
        localStorage.setItem('password', encrypt(password));
        localStorage.setItem('isRemember', remember + '');
      } else {
        localStorage.removeItem('username');
        localStorage.removeItem('password');
        localStorage.removeItem('isRemember');
      }
      try {
        const loginSuccess = await store.handleLogin({ username, password, code, uuid });
        !loginSuccess && getCode();
      } catch (error) {
        console.log(error);
      }
    });
  };

  // 获取验证码
  const getCode = async () => {
    try {
      const { img, uuid } = await store.handleGetCodeImg();
      if (!img || !uuid) {
        setIsValidate(false);
        return;
      }
      const url = 'data:image/gif;base64,' + img;
      setCodeUrl(url);
      setUuid(uuid);
    } catch (error) {
      console.log(error);
    }
  };

  const init = async () => {
    try {
      getThirdConfig();
      getCode();
      await store.getPasswordRegular();

      const username = localStorage.getItem('username');
      const password = decrypt(localStorage.getItem('password') || '');
      const remember = localStorage.getItem('isRemember') === 'true';

      if (remember) {
        const data = {
          username,
          password,
          remember,
        };
        form.setFieldsValue(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 确认修改密码
  const onConfirm = () => {
    form.validateFields().then(async () => {
      try {
        const data = form.getFieldsValue();
        const { username = '', oldPassword = '', newPassword = '' } = data || {};
        await updatePwd({ oldPassword, newPassword }, username);
        setIsUpdate(false);
        message.success('操作成功');
      } catch (error) {
        console.log(error);
      }
    });
  };

  // 其他方式登录 keycloak
  const keyCloakLogin = async () => {
    const { authUrl, state, responseType, scope, clientId, redirectUri, enable } =
      await getKeycloak();

    if (enable === false) {
      message.error('请稍后重试或联系管理员');
      return;
    }

    const urlSearchParams = new URLSearchParams(location.search);
    const service = urlSearchParams.get('service');

    const redirect_uri = encodeURIComponent(
      `${redirectUri}${service ? `?service=${encodeURIComponent(service)}` : ''}`,
    );
    const redirectUrl = `${authUrl}?state=${state}&client_id=${clientId}&response_type=${responseType}&redirect_uri=${redirect_uri}&scope=${scope}`;
    location.href = redirectUrl;
  };

  // 其他方式登录  钉钉扫码
  const dingLogin = () => {
    const urlSearchParams = new URLSearchParams(location.search);
    const service = urlSearchParams.get('service');
    location.href =
      location.origin +
      `/login/dingDtfLogin${service ? `?service=${encodeURIComponent(service)}` : ''}`;
  };

  // 获取三方登录配置
  const getThirdConfig = async () => {
    try {
      const thirdConfigMap = {
        dingding: dingLogin,
        keycloak: keyCloakLogin,
      } as {
        [key: string]: () => void;
      };
      const res = await getConfig();
      const resValue = JSON.parse(res.configValue) || [];
      const defaultValue: ConfigItem[] = [];
      Object.entries(resValue).forEach((item) => {
        if (item[1]) {
          const thirdItem = CONFIG_ITEM.filter((configItem) => configItem.value == item[0])?.[0];
          defaultValue.push({
            ...thirdItem,
            visible: item[1],
            handleOperate: thirdConfigMap[item[0]],
          });
        }
      });
      setThirdConfig(
        defaultValue.sort((a, b) => {
          return a.order - b.order;
        }),
      );
    } catch (e) {
      console.log(e);
    }
  };

  return {
    codeUrl,
    isValidate,
    isUpdate,
    handleLogin,
    init,
    getCode,
    setIsUpdate,
    onConfirm,
    thirdConfig,
  };
};
export default useLogin;
