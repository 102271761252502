import Store, { BaseStore } from 'zustand-store';
import { Apis } from '@bui/req';

interface Industry {
  bd_customer_industry_classification_id: string;
  data_source: string;
  name: string;
  number: string;
  superior_number: string;
  updated_at: string;
}

export type RegisterValues = {
  enterpriseName: string;
  tenantCode: string;
  enterpriseIndustry: string;
  enterpriseScale: string;
  enterpriseContactPhone: string;
  enterpriseContactEmail: string;
};

class UseRegisterStore extends BaseStore<UseRegisterStore> {
  industries: Array<{ label: string; value: string }> = [];
  scales: Array<{ label: string; value: string }> = [];
  success = false;
  public async getIndustries() {
    try {
      const res = await Apis.request<{ treeDataList: Array<Industry> }>({
        url: '/api/gateway/metabase/api/v1/base/bd_customer_industry_classification/query_tree_data',
        method: 'post',
        data: {
          condition: 'superior_number is null',
          idKey: 'number',
          pidKey: 'superior_number',
          namespaceID: 'base',
          moduleID: 'bd_customer_industry_classification',
        },
      });
      const industries = (res?.treeDataList || []).map((item) => {
        return {
          label: item.name,
          value: item.bd_customer_industry_classification_id,
        };
      });
      this.set({ industries });
    } catch (error) {
      //
      console.error('获取行业失败', error);
    }
  }
  public async getScales() {
    try {
      const res = await Apis.request<{
        set: Array<{ recordID: string; values: Array<{ name: string; value: string }> }>;
      }>({
        url: '/api/gateway/metabase/compose/listRecords/namespace/base/module/bd_basic_data_value',
        method: 'post',
        data: {
          moduleID: 'bd_basic_data_value',
          namespaceID: 'base',
          limit: 1000,
          filter: "type_code = 'enterprise_scale'",
        },
      });
      const scales = res.set.reduce((pre, cur) => {
        const value = cur.values.find((item) => {
          return item.name === 'value_code';
        });
        const name = cur.values.find((item) => {
          return item.name === 'value_name';
        });
        pre.push({
          label: name?.value,
          value: value?.value,
        });
        return pre;
      }, [] as any);

      this.set({
        scales,
      });
    } catch (error) {
      //
      console.error('获取行业失败', error);
    }
  }
  public getData() {
    this.getIndustries();
    this.getScales();
  }

  @Store.loading()
  async register(values: RegisterValues) {
    try {
      await Apis.request<{ treeDataList: Array<Industry> }>({
        url: '/api/gateway/metabase/tenant/enterprise',
        method: 'post',
        data: values,
      });
      this.set({ success: true });
    } catch (e) {
      console.log('注册失败', e);
    }
  }
}

export const useRegisterStore = Store.create(UseRegisterStore);
