import { Apis } from '@bui/req';
const baseURL = '/api/gateway';

interface KeycloakData {
  authUrl: string;
  state: string;
  responseType: string;
  scope: string;
  clientId: string;
  redirectUri: string;
  enable?: boolean | null;
}

interface IValidateRuleData {
  defaultPwd?: string;
  errorMsg?: string;
  pwdRule?: string;
  regularExpression?: string;
}

interface ILogoUrlData {
  configId?: number;
  configKey?: string;
  configName?: string;
  configType?: string;
  configValue?: string;
}

interface ILoginData {
  homePage?: IHomePageData;
  access_token?: string;
  userAccount?: string;
  userName?: string;
  userId?: number;
}

interface IHomePageData {
  name?: string;
  url?: string;
}

interface ICodeImgData {
  img?: string;
  uuid?: string;
}

interface AuthUrlData {
  authUrl: string;
  redirectUri: string;
  clientId: string;
}

export function login(userAccount: string, password: string, code: string, uuid: string) {
  return Apis.request<ILoginData>({
    url: `${baseURL}/system/auth/login`,
    method: 'post',
    data: { userAccount, password, code, uuid },
  });
}

export function getCodeImg() {
  return Apis.request<ICodeImgData>({
    url: `${baseURL}/code`,
    method: 'get',
  });
}

export function getLogoUrl() {
  return Apis.request<ILogoUrlData>({
    url: `${baseURL}/system/config/get`,
    method: 'post',
    data: { configKey: 'sys.logo.url' },
  });
}

export function updatePwd(
  params: { oldPassword?: string; newPassword?: string },
  userAccount: string,
) {
  return Apis.request<void>({
    url: `${baseURL}/system/user/profile/updatePwd/${userAccount}`,
    method: 'PUT',
    params,
  });
}

export const getValidateRule = async () => {
  return await Apis.request<IValidateRuleData>({
    url: `${baseURL}/system/user/profile/pwdrule`,
    method: 'get',
  });
};

export const getKeycloak = () => {
  return Apis.request<KeycloakData>({
    url: `${baseURL}/system/keycloak/config/authUrl`,
    method: 'get',
  });
};

export const getAuthUrl = () => {
  return Apis.request<AuthUrlData>({
    url: `${baseURL}/system/third/dingTalk/scanCode/authUrl`,
    method: 'get',
  });
};
