import { Apis } from '@bui/req';

export const addOrEditConfig = async (configValue: string) =>
  await Apis.request({
    url: '/api/gateway/system/config/addOrEdit',
    method: 'POST',
    data: {
      configName: '三方登录平台',
      configKey: 'sys.thirdLogin.platform',
      configValue: configValue,
    },
  });

export const getConfig = async () =>
  await Apis.request<{
    configValue: string;
    [key: string]: any;
  }>({
    url: '/api/gateway/system/config/get',
    method: 'POST',
    data: {
      configKey: 'sys.thirdLogin.platform',
    },
  });
